.input-number {
    .number {
        background-color: transparent;
        margin: 0;
        padding: 0;
        height: auto;
    }
}

.rudy-card {
    @apply bg-neutral-100 rounded-xl p-5;
}

.rudy-input__color {
    @apply h-9 w-full py-3 px-1.5 rounded-md placeholder:text-gray-400 text-sm bg-white;
}

.rudy-input__checkbox {
    @apply flex;
    input[type="checkbox"] {
        @apply hidden;
    }

    label {
        @apply flex gap-2 items-center cursor-pointer;

        &::before {
            content: "";
            @apply inline-block w-5 h-5 rounded bg-white;
        }
    }

    input[type="checkbox"]:checked + label::before {
        @apply bg-blue-500;
    }
}

.rudy-input__text {
    @apply h-9 w-full px-4 rounded-md placeholder:text-gray-400 text-sm;
}

textarea.rudy-input__text {
    @apply py-2;
}

.rudy-text__subtitle {
    @apply text-gray-900 opacity-50 text-lg font-semibold;

}

.rudy-text__subtitle--sm,
h4.rudy-text__subtitle--sm {
    @apply text-gray-600 font-semibold;
}

.rudy-text__title,
h3.rudy-text__title {
    @apply text-blue-500 text-lg font-semibold;
}

.rudy-text__paragraph__primary {
    @apply text-gray-400 text-lg;
}

.rudy-text__paragraph__primary--sm {
    @apply text-gray-500;
}

select.rudy-input__select {
    @apply border-none px-4 h-9 w-full rounded-md placeholder:text-gray-400 text-sm bg-white;
}

.rudy-button,
.rudy-button i{
    @apply cursor-pointer flex items-center justify-center;
}

.rudy-button__primary,
button.rudy-button__primary {
    @apply bg-blue-500 text-white rounded-md py-2 px-4 text-sm font-semibold;

    &:disabled {
        @apply bg-blue-200 text-white cursor-not-allowed;
    }
}

.rudy-button__secondary,
button.rudy-button__secondary {
    @apply bg-white text-blue-500 rounded-md py-2 px-4 text-sm font-semibold;

    &:disabled {
        @apply bg-gray-200 text-blue-500 cursor-not-allowed;
    }

    &.active {
        @apply rudy-button__primary;
    }
}

.rudy-button__ternary,
button.rudy-button__ternary {
    @apply bg-gray-300 text-blue-500 rounded-md py-2 px-4 text-sm font-semibold;

    &:disabled {
        @apply bg-gray-200 text-blue-500 cursor-not-allowed;
    }

    &.active {
        @apply rudy-button__primary;
    }
}

.rudy-column {
    @apply flex flex-col;
}

.rudy-card__settings-panel {
    max-height: 50vh;
}

.rudy-note {
    @apply bg-gray-200 rounded-xl p-8;
}

h4.rudy-note__title {
    @apply text-2xl text-gray-600;
}

.rudy-note__paragraph {
    @apply text-gray-500;
}

.rudy-certificate__qualifier {
    @apply flex gap-4 md:gap-0 flex-col md:grid py-4 rudy-text__paragraph__primary--sm;

    & > div {
        @apply md:mx-2;
    }

    & > div:first-child {
        @apply md:ml-0;
    }
}

.rudy-certificate__qualifier--two-columns {
    @apply grid-cols-2 md:w-2/3 rudy-certificate__qualifier;
}

.rudy-certificate__qualifier--three-columns {
    @apply grid-cols-3 w-full rudy-certificate__qualifier;

    & > div:last-child {
        @apply md:mr-0;
    }
}

.rudy-card__child {
    @apply bg-gray-200 rounded-lg p-4;
}

.rudy-table {
    th.rudy-table__header {
        @apply border-none font-normal p-0 px-1;
        max-width: 85px;

        input {
            @apply font-normal;
        }
    }
}

.rudy-rollup {
    .rudy-rollup__header {
        @apply py-2 px-4 cursor-pointer flex justify-between items-center;
    }

    .rudy-rollup__header {
        .rudy-rollup__header-icon {
            transition: transform .5s ease;
        }
    }

    .rudy-rollup__body {
        max-height: 0;
        transition: max-height .5s ease;
        @apply overflow-hidden;
    }

    &.expanded {
        .rudy-rollup__header {
            .rudy-rollup__header-icon {
                @apply rotate-180;
            }
        }

        .rudy-rollup__body {
            max-height: 2000px;
        }
    }
}

/* Template settings */
.template-editor__image-container {
    @apply relative w-full min-h-max flex justify-center;
    box-shadow: 0 0 15px 0 #c7c4c4;
}

.template-editor__header-line {
    @apply bg-red-700 text-red-700 top-0;
}

.template-editor__footer-line {
    @apply bg-red-700 text-red-700 bottom-0;
}

.template-editor__left-line {
    @apply bg-blue-700 text-blue-700 left-0;
}

.template-editor__right-line {
    @apply bg-blue-700 text-blue-700 right-0;
}

.template-editor__header-line,
.template-editor__footer-line {
    @apply absolute z-10 cursor-ns-resize;
    width: calc(100% + 170px);
    height: 2px;
    margin-left: -130px;

    span {
        @apply select-none;
    }
}

.template-editor__right-line,
.template-editor__left-line {
    @apply absolute z-10 cursor-ew-resize;
    width: 2px;
    height: 100vh;
    margin-top: -40px;
    margin-left: -0px;
    span {
        @apply select-none;
    }
}

.template-editor__used-sections {
    @apply absolute inset-0;
    top: 10%;
    bottom: 10%;
}

.template-editor__sections-container {
    @apply flex flex-col gap-2;
}

.template-editor__setting-container {
    @apply relative bg-gray-200 rounded;
    min-height: 2.75rem;
}

.template-editor__section {
    @apply border-dashed border-2 border-blue-300 rounded cursor-move hover:bg-blue-100 transition;
}

.text-inherit {
    text-align: inherit;
}

.ck.ck-content.ck-editor__editable {
    @apply cursor-auto;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    @apply cursor-move;
}

.ck.ck-content,
span {
    strong {
        color: inherit;
    }
}

.ml-8 {
    margin-left: 2rem;
}

.navbar-end a.navbar-item:hover {
    opacity: 1;
    background-color: hsl(var(--background-hue), var(--background-saturation), var(--background-lightness));
}
