@charset "utf-8";

@import "../../node_modules/bulma/bulma.sass";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import "./custom.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

